import React from 'react';

function HomePage() {

    return (
        <div>
            {/* <pre>{process.env.REACT_APP_SECRET_NAME}</pre> */}
            <h1 className="logo-heading">Bitcoin <span className="head-span">&nbsp;or Gold?</span></h1>
            <h6 className="logo-subtitle">Dear Goldbug, <span className="white-text">Bitcoin wins at everything</span> Gold wanted to
            do.</h6>
            <div className="curve-line-image">
            <img src="images/curver-line-image.png" alt="" />
            </div>
            <div className="banner-section">
            <img src="images/banner-img-full.png" alt="" />
            </div>
            <section className="bottom_container">
            <div className="orderedlist-gold">
                <h3>Why you might ask?</h3>
                <ol>
                <li><span className="number-round">1</span><span className="content-round">Gold can easily be taken from you.</span></li>
                <li><span className="number-round">2</span><span className="content-round">You can't self-validate gold's supply.</span></li>
                <li><span className="number-round">3</span><span className="content-round">You can't guarantee gold's S2F (Stock-to-Flow).</span></li>
                <li><span className="number-round">4</span><span className="content-round">You can't self-validate/verify gold's authenticity.</span></li>
                <li><span className="number-round">5</span><span className="content-round">You can't transport gold in any practical way.</span></li>
                <li><span className="number-round">6</span><span className="content-round">Gold is not divisible, you can send 1/1,000,000 of a Bitcoin.</span></li>
                </ol>
            </div>
            <div className="winner_span">
                <div className="winnerset">
                <h6>Winner</h6>
                <span className="span-border-winner activewinner"><img src="images/winner1.png" alt="" /></span>
                </div>
                <span className="span-vs">vs</span>
                <div className="winnerset">
                <h6>  </h6>
                <span className="span-border-winner"><img src="images/winner2.png" alt="" /></span>
                </div>
            </div>
            <div className="goldstandard_para">
                <p>Gold had a good run, the Gold standard
                failed because the only way gold can be used in
                practice is by IOU. This means it will always be cheated, confiscated and manipulated.
                Bitcoin solves those problems.</p>
                <p className="linkline-para">It's time for the <a href="https://saifedean.com/book/">
                    Bitcoin standard.</a></p>
            </div>
            </section>
        </div>
    );
}

export { HomePage };